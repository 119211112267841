import React from "react";
import ValueOfVehicle from "./pages/ValueOfVehicle";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ValueOfVehicle />
      </LocalizationProvider>
    </div>
  );
}
