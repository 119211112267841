import React, { useState } from "react";
import classes from "./ValueOfVehicle.module.css";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function ValueOfVehicle() {
  const [workingVolume, setWorkingVolume] = useState("");
  const [enginePower, setEnginePower] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const calculateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
    return age;
  };

  const ageOfVehicle = calculateAge(selectedDate);
  let ageCoefficient;
  if (ageOfVehicle < 2) {
    ageCoefficient = 1;
  } else if (ageOfVehicle >= 2 && ageOfVehicle < 3) {
    ageCoefficient = 0.9;
  } else if (ageOfVehicle >= 3 && ageOfVehicle < 4) {
    ageCoefficient = 0.8;
  } else if (ageOfVehicle >= 4 && ageOfVehicle < 5) {
    ageCoefficient = 0.7;
  } else if (ageOfVehicle >= 5 && ageOfVehicle < 6) {
    ageCoefficient = 0.6;
  } else if (ageOfVehicle >= 6 && ageOfVehicle < 7) {
    ageCoefficient = 0.5;
  } else if (ageOfVehicle >= 7 && ageOfVehicle < 8) {
    ageCoefficient = 0.45;
  } else if (ageOfVehicle >= 8 && ageOfVehicle < 9) {
    ageCoefficient = 0.4;
  } else if (ageOfVehicle >= 9 && ageOfVehicle < 10) {
    ageCoefficient = 0.35;
  } else if (ageOfVehicle >= 10) {
    ageCoefficient = 0.3;
  }

  return (
    <div className={classes.container}>
      <TextField
        id="outlined-basic"
        label="Radna zapremina"
        variant="outlined"
        type="number"
        className={classes.input}
        value={workingVolume}
        onChange={(e) => setWorkingVolume(e.target.value)}
      />
      <TextField
        id="outlined-basic"
        label="Snaga motora"
        variant="outlined"
        type="number"
        className={classes.input}
        value={enginePower}
        onChange={(e) => setEnginePower(e.target.value)}
      />
      <DatePicker
        label="Datum (Prve Registracije/Starosti)"
        value={selectedDate}
        onChange={handleDateChange}
        textField={(params) => <TextField {...params} />}
        format="DD/MM/YYYY"
        className={classes.input}
      />
      <TextField
        id="outlined-basic"
        label="Koeficijent starosti"
        variant="outlined"
        className={classes.input}
        value={`${ageCoefficient * 100}%`}
      />
      <p className={classes.paragraf}>Procenjena vrednost vozila:</p>
      <TextField
        id="outlined-basic"
        variant="outlined"
        className={classes.input}
        value={
          workingVolume && enginePower && selectedDate
            ? (
                (workingVolume * 320 + enginePower * 6400) *
                ageCoefficient
              ).toLocaleString() + " RSD"
            : "Unesite podatke"
        }
      />
    </div>
  );
}
